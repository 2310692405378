import { chakra } from '@chakra-ui/react'
import * as React from 'react'

export const PenPaper = (props) => (
  <chakra.svg w="100%" maxW={400} viewBox="0 0 428 483" fill="none" {...props}>
    <title>Pen and paper representing a contract.</title>
    <path
      fill="url(#pen-paper-paint0_linear)"
      fillRule="evenodd"
      d="M204.043 8.025a28 28 0 0127.914 0l182 104.65A28 28 0 01428 136.949v209.102a28 28 0 01-14.043 24.274l-182 104.65a28.003 28.003 0 01-27.914 0l-182-104.65A28.002 28.002 0 018 346.051V136.949a28.002 28.002 0 0114.043-24.274l182-104.65z"
      clipRule="evenodd"
      opacity="0.048"
    ></path>
    <g filter="url(#pen-paper-filter0_d)">
      <path
        fill="#fff"
        fillOpacity="0.552"
        fillRule="evenodd"
        d="M46.627 282.795c-2.73-1.365-2.73-5.262 0-6.627l125.669-62.835a14.818 14.818 0 0113.254 0l166.42 83.21c2.73 1.365 2.73 5.262 0 6.627l-125.669 62.835a14.818 14.818 0 01-13.254 0l-166.42-83.21z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#pen-paper-filter1_f)">
      <circle cx="224.131" cy="310.417" r="15.559" fill="#505050"></circle>
    </g>
    <g filter="url(#pen-paper-filter2_di)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M279.334 250.372a135.283 135.283 0 01-35.518 40.231l-9.957 7.535c-3.445 2.608-8.33-.213-7.795-4.5l1.548-12.39a135.285 135.285 0 0117.081-50.876l57.642-99.838c5.523-9.566 17.755-12.843 27.321-7.32 9.565 5.522 12.843 17.754 7.32 27.32l-57.642 99.838z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="pen-paper-filter0_d"
        width="397.439"
        height="243.8"
        x="0.579"
        y="199.769"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="32"></feOffset>
        <feGaussianBlur stdDeviation="22"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="pen-paper-filter1_f"
        width="107.231"
        height="107.231"
        x="170.515"
        y="256.801"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feGaussianBlur
          result="effect1_foregroundBlur"
          stdDeviation="19.028"
        ></feGaussianBlur>
      </filter>
      <filter
        id="pen-paper-filter2_di"
        width="150.827"
        height="203.26"
        x="207.148"
        y="109.214"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="11"></feOffset>
        <feGaussianBlur stdDeviation="8"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.683141 0 0 0 0 0.683141 0 0 0 0 0.683141 0 0 0 0.666876 0"></feColorMatrix>
        <feBlend in2="shape" result="effect2_innerShadow"></feBlend>
      </filter>
      <linearGradient
        id="pen-paper-paint0_linear"
        x1="8"
        x2="8"
        y1="0"
        y2="483"
        gradientUnits="userSpaceOnUse"
      >
        <stop></stop>
        <stop offset="1" stopColor="#fff"></stop>
      </linearGradient>
    </defs>
  </chakra.svg>
)
export const Stack = (props) => (
  <chakra.svg w="100%" maxW={400} viewBox="0 0 420 483" fill="none" {...props}>
    <title>Planes stacked with a bolt and a cylinder representing your dev stack.</title>
    <path
      fill="url(#stack-paint0_linear)"
      fillRule="evenodd"
      d="M196.043 8.025a28 28 0 0127.914 0l182 104.65A28 28 0 01420 136.949v209.102a28 28 0 01-14.043 24.274l-182 104.65a28.003 28.003 0 01-27.914 0l-182-104.65A28.002 28.002 0 010 346.051V136.949a28.002 28.002 0 0114.043-24.274l182-104.65z"
      clipRule="evenodd"
      opacity="0.048"
    ></path>
    <g filter="url(#stack-filter0_d)">
      <path
        fill="#9C9C9D"
        fillRule="evenodd"
        d="M52.349 291.383c-3.852-1.926-3.852-7.423 0-9.349l146.501-73.25a23.995 23.995 0 0121.466 0l146.501 73.25c3.852 1.926 3.852 7.423 0 9.349l-146.501 73.25a23.995 23.995 0 01-21.466 0l-146.501-73.25z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#stack-filter1_d)">
      <path
        fill="#9C9C9D"
        fillRule="evenodd"
        d="M52.349 252.187c-3.852-1.926-3.852-7.423 0-9.349l146.501-73.25a23.995 23.995 0 0121.466 0l146.501 73.25c3.852 1.926 3.852 7.423 0 9.349l-146.501 73.25a23.995 23.995 0 01-21.466 0l-146.501-73.25z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#stack-filter2_d)">
      <path
        fill="#9C9C9D"
        fillRule="evenodd"
        d="M52.349 217.564c-3.852-1.926-3.852-7.423 0-9.349l146.501-73.25a23.995 23.995 0 0121.466 0l146.501 73.25c3.852 1.926 3.852 7.423 0 9.349l-146.501 73.25a23.995 23.995 0 01-21.466 0l-146.501-73.25z"
        clipRule="evenodd"
      ></path>
    </g>
    <g filter="url(#stack-filter3_f)">
      <ellipse
        cx="267.669"
        cy="230.05"
        fill="#6A6A6A"
        rx="48.995"
        ry="26.131"
      ></ellipse>
    </g>
    <g filter="url(#stack-filter4_d)">
      <g filter="url(#stack-filter5_i)">
        <path
          fill="#E4E4E4"
          fillRule="evenodd"
          d="M311.792 135.933h-90.118v55.597h.001l-.001.126c0 12.157 20.173 22.012 45.059 22.012 24.885 0 45.059-9.855 45.059-22.012l-.001-.126h.001v-55.597z"
          clipRule="evenodd"
        ></path>
      </g>
      <ellipse
        cx="266.733"
        cy="135.933"
        fill="#fff"
        rx="45.059"
        ry="22.014"
      ></ellipse>
    </g>
    <g filter="url(#stack-filter6_f)">
      <ellipse
        cx="151.995"
        cy="229.131"
        fill="#6A6A6A"
        rx="48.995"
        ry="26.131"
      ></ellipse>
    </g>
    <g filter="url(#stack-filter7_d)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M159.371 113a7.58 7.58 0 00-5.352 2.21l-44.802 44.664a7.53 7.53 0 000 10.672 7.58 7.58 0 005.352 2.21l26.492-.001-.001 32.699c0 4.168 3.389 7.546 7.569 7.546a7.58 7.58 0 005.352-2.21l44.802-44.664a7.53 7.53 0 000-10.672 7.58 7.58 0 00-5.352-2.21l-26.49-.001-.001-32.697c0-4.168-3.389-7.546-7.569-7.546z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <filter
        id="stack-filter0_d"
        width="408.246"
        height="248.917"
        x="5.46"
        y="194.25"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="32"></feOffset>
        <feGaussianBlur stdDeviation="22"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="stack-filter1_d"
        width="408.246"
        height="248.917"
        x="5.46"
        y="155.054"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="32"></feOffset>
        <feGaussianBlur stdDeviation="22"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="stack-filter2_d"
        width="408.246"
        height="248.917"
        x="5.46"
        y="120.431"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="32"></feOffset>
        <feGaussianBlur stdDeviation="22"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="stack-filter3_f"
        width="169.02"
        height="123.292"
        x="183.159"
        y="168.404"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feGaussianBlur
          result="effect1_foregroundBlur"
          stdDeviation="17.758"
        ></feGaussianBlur>
      </filter>
      <filter
        id="stack-filter4_d"
        width="106.118"
        height="115.749"
        x="213.674"
        y="105.92"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="4"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.540811 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="stack-filter5_i"
        width="102.118"
        height="77.735"
        x="209.674"
        y="135.933"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-12"></feOffset>
        <feGaussianBlur stdDeviation="6.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.494169 0 0 0 0 0.494169 0 0 0 0 0.494169 0 0 0 0.5 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
      </filter>
      <filter
        id="stack-filter6_f"
        width="169.02"
        height="123.292"
        x="67.485"
        y="167.485"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feGaussianBlur
          result="effect1_foregroundBlur"
          stdDeviation="17.758"
        ></feGaussianBlur>
      </filter>
      <filter
        id="stack-filter7_d"
        width="116"
        height="122"
        x="96"
        y="102"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="5.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.550208 0"></feColorMatrix>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="stack-paint0_linear"
        x1="0"
        x2="0"
        y1="0"
        y2="483"
        gradientUnits="userSpaceOnUse"
      >
        <stop></stop>
        <stop offset="1" stopColor="#fff"></stop>
      </linearGradient>
    </defs>
  </chakra.svg>
)
export const LightBulb = (props) => (
  <chakra.svg viewBox="0 0 420 483" w="100%" maxW={400} {...props}>
    <title>Lightbulb representing the onboarding meeting.</title>
    <path
      opacity="0.047912"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.043 8.02538C204.684 3.05655 215.316 3.05655 223.957 8.02539L405.957 112.675C414.645 117.671 420 126.928 420 136.949V346.051C420 356.072 414.645 365.329 405.957 370.325L223.957 474.975C215.316 479.943 204.684 479.943 196.043 474.975L14.0428 370.325C5.35546 365.329 0 356.072 0 346.051L0 136.949C0 126.928 5.35546 117.671 14.0428 112.675L196.043 8.02538Z"
      fill="url(#lb-paint0_linear)"
    />
    <g filter="url(#lb-filter0_di)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.217 308.596C150.217 289.938 143.561 271.893 131.447 257.705C116.191 239.857 107 216.692 107 191.397C107 134.882 152.876 89 209.382 89C265.888 89 311.764 134.882 311.764 191.397C311.764 216.703 302.565 239.878 287.33 257.757C275.234 271.912 268.59 289.92 268.59 308.539C268.547 308.54 268.547 308.541 268.547 308.541C268.547 340.847 242.362 367.035 210.061 367.035H208.648C176.377 367.035 150.217 340.871 150.217 308.596C150.217 308.596 150.217 308.596 150.217 308.596Z"
        fill="white"
      />
    </g>
    <mask
      id="lb-mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="107"
      y="89"
      width="205"
      height="279"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.217 308.596C150.217 289.938 143.561 271.893 131.447 257.705C116.191 239.857 107 216.692 107 191.397C107 134.882 152.876 89 209.382 89C265.888 89 311.764 134.882 311.764 191.397C311.764 216.703 302.565 239.878 287.33 257.757C275.234 271.912 268.59 289.92 268.59 308.539C268.547 308.54 268.547 308.541 268.547 308.541C268.547 340.847 242.362 367.035 210.061 367.035H208.648C176.377 367.035 150.217 340.871 150.217 308.596C150.217 308.596 150.217 308.596 150.217 308.596Z"
        fill="white"
      />
    </mask>
    <g mask="url(#lb-mask0)">
      <g opacity="0.147391">
        <circle
          cx="190.5"
          cy="243.5"
          r="15.5"
          stroke="#757575"
          strokeWidth="8"
        />
        <circle
          cx="228.5"
          cy="243.5"
          r="15.5"
          stroke="#757575"
          strokeWidth="8"
        />
        <path
          d="M205 249.5C205 247.015 207.015 245 209.5 245V245C211.985 245 214 247.015 214 249.5V332H205V249.5Z"
          fill="#979797"
        />
      </g>
    </g>
    <g filter="url(#lb-filter1_bd)">
      <path
        d="M150.963 337.133C150.963 334.508 153.091 332.38 155.716 332.38H264.237C266.861 332.38 268.989 334.508 268.989 337.133V373.768C268.989 384.705 260.123 393.571 249.186 393.571H170.766C159.829 393.571 150.963 384.705 150.963 373.768V337.133Z"
        fill="white"
        fillOpacity="0.551573"
      />
    </g>
    <defs>
      <filter
        id="lb-filter0_di"
        x="92"
        y="76"
        width="225.764"
        height="297.035"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.752896 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-21" dy="-13" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.145023 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="lb-filter1_bd"
        x="131.584"
        y="313.001"
        width="156.784"
        height="99.9494"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="9.68947" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur"
          result="effect2_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="lb-paint0_linear"
        x1="0"
        y1="0"
        x2="0"
        y2="483"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </chakra.svg>
)
export const Slack = (props) => (
  <chakra.svg viewBox="0 0 420 483" fill="none" w="100%" maxW={400} {...props}>
    <title>An illustration with the Slack logo representing our way of communication.</title>
    <path
      opacity="0.047912"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.043 8.02538C204.684 3.05655 215.316 3.05655 223.957 8.02539L405.957 112.675C414.645 117.671 420 126.928 420 136.949V346.051C420 356.072 414.645 365.329 405.957 370.325L223.957 474.975C215.316 479.943 204.684 479.943 196.043 474.975L14.0428 370.325C5.35546 365.329 0 356.072 0 346.051L0 136.949C0 126.928 5.35546 117.671 14.0428 112.675L196.043 8.02538Z"
      fill="url(#slack-paint0_linear)"
    />
    <rect
      x="82"
      y="146"
      width="256"
      height="190.599"
      rx="24.9756"
      fill="white"
      fillOpacity="0.546738"
    />
    <g filter="url(#slack-filter0_d)">
      <g filter="url(#slack-filter1_di)">
        <path
          d="M220.451 252.672C220.451 257.734 216.36 261.825 211.298 261.825C206.237 261.825 202.146 257.734 202.146 252.672C202.146 247.611 206.237 243.52 211.298 243.52H220.451V252.672Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter2_di)">
        <path
          d="M225.028 252.672C225.028 247.611 229.119 243.52 234.181 243.52C239.243 243.52 243.334 247.611 243.334 252.672V275.555C243.334 280.617 239.243 284.708 234.181 284.708C229.119 284.708 225.028 280.617 225.028 275.555V252.672Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter3_di)">
        <path
          d="M234.181 215.922C229.119 215.922 225.028 211.831 225.028 206.769C225.028 201.707 229.119 197.616 234.181 197.616C239.243 197.616 243.334 201.707 243.334 206.769V215.922H234.181Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter4_di)">
        <path
          d="M234.181 220.568C239.243 220.568 243.334 224.659 243.334 229.721C243.334 234.783 239.243 238.874 234.181 238.874H211.229C206.167 238.874 202.076 234.783 202.076 229.721C202.076 224.659 206.167 220.568 211.229 220.568H234.181Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter5_di)">
        <path
          d="M270.862 229.721C270.862 224.659 274.953 220.568 280.015 220.568C285.077 220.568 289.168 224.659 289.168 229.721C289.168 234.783 285.077 238.874 280.015 238.874H270.862V229.721Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter6_di)">
        <path
          d="M266.285 229.721C266.285 234.783 262.194 238.874 257.132 238.874C252.071 238.874 247.979 234.783 247.979 229.721V206.769C247.979 201.707 252.071 197.616 257.132 197.616C262.194 197.616 266.285 201.707 266.285 206.769V229.721Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter7_di)">
        <path
          d="M257.132 266.402C262.194 266.402 266.285 270.493 266.285 275.555C266.285 280.617 262.194 284.708 257.132 284.708C252.071 284.708 247.979 280.617 247.979 275.555V266.402H257.132Z"
          fill="white"
        />
      </g>
      <g filter="url(#slack-filter8_di)">
        <path
          d="M257.132 261.825C252.071 261.825 247.979 257.734 247.979 252.672C247.979 247.611 252.071 243.52 257.132 243.52H280.084C285.146 243.52 289.237 247.611 289.237 252.672C289.237 257.734 285.146 261.825 280.084 261.825H257.132Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#slack-filter9_d)">
      <path
        d="M82 170.976C82 157.182 93.182 146 106.976 146H153.755V336.599H106.976C93.182 336.599 82 325.417 82 311.623V170.976Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="slack-filter0_d"
        x="197.076"
        y="192.616"
        width="105.161"
        height="105.092"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.321445 0 0 0 0 0.321445 0 0 0 0 0.321445 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="slack-filter1_di"
        x="194.146"
        y="235.52"
        width="34.3059"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter2_di"
        x="217.028"
        y="235.52"
        width="34.3059"
        height="57.1884"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter3_di"
        x="217.028"
        y="189.616"
        width="34.3059"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter4_di"
        x="194.076"
        y="212.568"
        width="57.2577"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter5_di"
        x="262.862"
        y="212.568"
        width="34.3059"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter6_di"
        x="239.979"
        y="189.616"
        width="34.3059"
        height="57.2577"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter7_di"
        x="239.979"
        y="258.402"
        width="34.3059"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter8_di"
        x="239.979"
        y="235.52"
        width="57.2577"
        height="34.3059"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 0 0.86982 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="slack-filter9_d"
        x="78"
        y="142"
        width="79.7547"
        height="198.599"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="slack-paint0_linear"
        x1="0"
        y1="0"
        x2="0"
        y2="483"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </chakra.svg>
)
export const Microchip = (props) => (
  <chakra.svg viewBox="0 0 420 483" fill="none" w="100%" maxW={400} {...props}>
    <title>A Microchip with a bug representing the filing of issues.</title>
    <path
      fill="url(#mc-paint0_linear)"
      fillRule="evenodd"
      d="M196.043 8.02538c8.641-4.96883 19.273-4.96883 27.914.00001l182 104.64961c8.688 4.996 14.043 14.253 14.043 24.274v209.102c0 10.021-5.355 19.278-14.043 24.274l-182 104.65c-8.641 4.968-19.273 4.968-27.914 0L14.0428 370.325C5.35546 365.329 0 356.072 0 346.051V136.949c0-10.021 5.35546-19.278 14.0428-24.274L196.043 8.02538Z"
      clipRule="evenodd"
      opacity=".047912"
    />
    <g filter="url(#mc-filter0_d)">
      <rect
        width="241"
        height="241"
        x="89"
        y="121"
        fill="#fff"
        fillOpacity=".551573"
        rx="24"
      />
    </g>
    <g filter="url(#mc-filter1_di)">
      <rect width="131" height="131" x="144" y="176" fill="#fff" rx="17.029" />
    </g>
    <g filter="url(#mc-filter2_d)">
      <path
        fill="#fff"
        d="M153 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter3_d)">
      <path
        fill="#fff"
        d="M175 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter4_d)">
      <path
        fill="#fff"
        d="M197 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter5_d)">
      <path
        fill="#fff"
        d="M218 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter6_d)">
      <path
        fill="#fff"
        d="M240 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter7_d)">
      <path
        fill="#fff"
        d="M262 110c0-1.657 1.343-3 3-3s3 1.343 3 3v11h-6v-11Z"
      />
    </g>
    <g filter="url(#mc-filter8_d)">
      <path
        fill="#fff"
        d="M153 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter9_d)">
      <path
        fill="#fff"
        d="M175 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter10_d)">
      <path
        fill="#fff"
        d="M197 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter11_d)">
      <path
        fill="#fff"
        d="M218 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter12_d)">
      <path
        fill="#fff"
        d="M240 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter13_d)">
      <path
        fill="#fff"
        d="M262 373c0 1.657 1.343 3 3 3s3-1.343 3-3v-11h-6v11Z"
      />
    </g>
    <g filter="url(#mc-filter14_d)">
      <path
        fill="#fff"
        d="M78 300c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter15_d)">
      <path
        fill="#fff"
        d="M78 278c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter16_d)">
      <path
        fill="#fff"
        d="M78 256c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter17_d)">
      <path
        fill="#fff"
        d="M78 234c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter18_d)">
      <path
        fill="#fff"
        d="M78 212c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter19_d)">
      <path
        fill="#fff"
        d="M78 190.5c-1.6569 0-3-1.343-3-3s1.3431-3 3-3h11v6H78Z"
      />
    </g>
    <g filter="url(#mc-filter20_d)">
      <path
        fill="#fff"
        d="M341 300c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter21_d)">
      <path
        fill="#fff"
        d="M341 278c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter22_d)">
      <path
        fill="#fff"
        d="M341 256c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter23_d)">
      <path
        fill="#fff"
        d="M341 234c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter24_d)">
      <path
        fill="#fff"
        d="M341 212c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter25_d)">
      <path
        fill="#fff"
        d="M341 190.5c1.657 0 3-1.343 3-3s-1.343-3-3-3h-11v6h11Z"
      />
    </g>
    <g filter="url(#mc-filter26_di)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m297.102 147.175 3.229-3.229 2.298 2.298-2.401 2.402c.436.322.862.655 1.252 1.045l1.149 1.15 2.298-2.299 2.298 2.299-2.298 2.298 1.149 1.149c.391.39.724.816 1.046 1.252l2.402-2.401 2.298 2.298-3.229 3.229c.862 3.251.034 6.859-2.517 9.41-2.55 2.551-6.158 3.378-9.41 2.517l-3.229 3.229-2.298-2.299 2.401-2.401c-.436-.322-.861-.655-1.252-1.046l-1.149-1.149-2.298 2.298-2.298-2.298 2.298-2.298-1.149-1.149c-.391-.391-.724-.816-1.046-1.252l-2.401 2.401-2.299-2.298 3.229-3.229c-.379-1.413-.436-2.895-.172-4.332l-3.735-.011v-3.241l4.999-.011c.391-.643.85-1.241 1.425-1.816.574-.574 1.172-1.034 1.827-1.436v-4.987h3.24v3.746c1.448-.276 2.93-.218 4.343.161Zm.931 8.262-4.596 4.596-2.298-2.298 4.596-4.596 2.298 2.298Zm4.596 4.596-4.596 4.596-2.298-2.298 4.596-4.596 2.298 2.298Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="mc-filter0_d"
        width="329"
        height="329"
        x="45"
        y="109"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="32" />
        <feGaussianBlur stdDeviation="22" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.220799 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter1_di"
        width="155"
        height="155"
        x="132"
        y="164"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.634124 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          in="SourceAlpha"
          radius="4"
          result="effect2_innerShadow"
        />
        <feOffset dx="-4" dy="-4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.800017 0 0 0 0 0.800017 0 0 0 0 0.800017 0 0 0 0.654993 0" />
        <feBlend in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="mc-filter2_d"
        width="14"
        height="22"
        x="149"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter3_d"
        width="14"
        height="22"
        x="171"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter4_d"
        width="14"
        height="22"
        x="193"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter5_d"
        width="14"
        height="22"
        x="214"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter6_d"
        width="14"
        height="22"
        x="236"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter7_d"
        width="14"
        height="22"
        x="258"
        y="103"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter8_d"
        width="14"
        height="22"
        x="149"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter9_d"
        width="14"
        height="22"
        x="171"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter10_d"
        width="14"
        height="22"
        x="193"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter11_d"
        width="14"
        height="22"
        x="214"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter12_d"
        width="14"
        height="22"
        x="236"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter13_d"
        width="14"
        height="22"
        x="258"
        y="358"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter14_d"
        width="22"
        height="14"
        x="71"
        y="290"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter15_d"
        width="22"
        height="14"
        x="71"
        y="268"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter16_d"
        width="22"
        height="14"
        x="71"
        y="246"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter17_d"
        width="22"
        height="14"
        x="71"
        y="224"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter18_d"
        width="22"
        height="14"
        x="71"
        y="202"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter19_d"
        width="22"
        height="14"
        x="71"
        y="180.5"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter20_d"
        width="22"
        height="14"
        x="326"
        y="290"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter21_d"
        width="22"
        height="14"
        x="326"
        y="268"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter22_d"
        width="22"
        height="14"
        x="326"
        y="246"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter23_d"
        width="22"
        height="14"
        x="326"
        y="224"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter24_d"
        width="22"
        height="14"
        x="326"
        y="202"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter25_d"
        width="22"
        height="14"
        x="326"
        y="180.5"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="mc-filter26_di"
        width="41.0676"
        height="41.0676"
        x="275.201"
        y="137.201"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.608299 0 0 0 0 0.608299 0 0 0 0 0.608299 0 0 0 0.5 0" />
        <feBlend in2="shape" result="effect2_innerShadow" />
      </filter>
      <linearGradient
        id="mc-paint0_linear"
        x1="0"
        x2="0"
        y1="0"
        y2="483"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
    </defs>
  </chakra.svg>
)
