import { chakra, useToken } from '@chakra-ui/react'
import * as React from 'react'

export const LogoStripped = (props) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <chakra.svg fill="none" viewBox="0 0 260 300" {...rest}>
      <title id="carbonQALogoStrippedID">CarbonQA Logo without text</title>
      <g filter="url(#filter0_b)" opacity="0.549">
        <path
          fill={color}
          fillRule="evenodd"
          d="M119.015 6.193a21.405 21.405 0 0121.426 0l108.323 62.625a21.404 21.404 0 0110.692 18.53v125.299a21.404 21.404 0 01-10.692 18.531l-108.323 62.624a21.404 21.404 0 01-21.426 0L10.691 231.178A21.405 21.405 0 010 212.647V87.349a21.404 21.404 0 0110.691-18.531L119.015 6.193z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill={color}
        fillRule="evenodd"
        d="M119.057 62.892a21.405 21.405 0 0121.339 0l59.675 34.313a21.405 21.405 0 0110.735 18.556v68.476c0 7.66-4.094 14.737-10.735 18.555l-59.675 34.314a21.406 21.406 0 01-21.339 0l-59.676-34.314a21.403 21.403 0 01-10.734-18.555v-68.476A21.405 21.405 0 0159.38 97.205l59.676-34.313z"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter
          id="filter0_b"
          width="280.86"
          height="314.761"
          x="-10.702"
          y="-7.383"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImage"
            stdDeviation="5.351"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </chakra.svg>
  )
}
