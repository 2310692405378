import * as React from 'react'
import { Box, Grid, Heading, Text, Img } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'
import { CtaSimple } from '../components/ctaSimple'
import { LogoStripped } from '../assets/svg/LogoStripped'
import * as Illustrations from '../assets/svg/HowItWorksIllustrations'
import HexStrokeOpacitySmall from '../assets/svg/flair/stroke-hex-opacity-small.svg'
import HexStrokeOpacity from '../assets/svg/flair/stroke-hex-opacity.svg'

const strings = {
  stepOne: {
    title: 'Sign on with us',
    subtext: `Sign our super-simple contract. You get a US-based, dedicated QA team ready to test.
    It’s month-to-month, so you can cancel anytime if your needs change.`,
  },
  stepTwo: {
    title: 'Tell us about your work',
    subtext:
      'Complete a short survey that tells us a bit more about your stack, environments, app, and any current testing you’re doing.',
  },
  stepThree: {
    title: 'Carbon Kickoff',
    subtext:
      'This is our onboarding meeting: a walkthrough of your current app, your most critical needs, and how you want us filing issues.',
  },
  stepFour: {
    title: 'Join us in Slack',
    subtext:
      'We invite you to a shared channel in Slack so both of our teams can communicate directly.',
  },
  stepFive: {
    title: "It's testing time!",
    subtext: `Our dedicated team starts testing your app and filing issues in the bug tracker of your choice.
    Your team can ask them questions directly through Slack or through the tracker.`,
  },
}

const StepInfo = ({ title, subtext }) => {
  return (
    <Box
      justifySelf="center"
      alignSelf="center"
      pr={{ base: 0, sm: 6 }}
      w="100%"
      maxW={{ base: '240px', sm: '400px' }}
      className="info--text__wrapper"
    >
      <Heading
        as="h3"
        size="lg"
        fontSize={{ base: '4xl' }}
        fontWeight="bold"
        lineHeight="shorter"
        mb="2"
        textAlign={{ base: 'center', sm: 'left' }}
      >
        {title}
      </Heading>
      <Text
        fontSize={{ base: 'lg', md: '2xl' }}
        textAlign={{ base: 'center', sm: 'left' }}
      >
        {subtext}
      </Text>
    </Box>
  )
}

const StepImage = ({ image }) => {
  return (
    <Box
      w="100%"
      maxW="420px"
      justifySelf="center"
      alignSelf="center"
      pl={{ base: 0, sm: 6 }}
      className="info--img"
    >
      {image}
    </Box>
  )
}

const Line = ({ top, bot }) => {
  return (
    <Box
      className="line"
      w={0}
      h={top || bot ? '50%' : '100%'}
      bg="transparent"
      borderLeft="4px solid white"
      position="absolute"
      zIndex={1}
      top={top || !bot ? 'unset' : 0}
      bottom={top || !bot ? 0 : 'unset'}
      right="calc(50% - 2px)"
      boxShadow="0 0 4px 1px white"
    />
  )
}

const StepIndicator = ({ step, top, bot }) => {
  return (
    <Box
      alignSelf="end"
      justifySelf="center"
      height="100%"
      position="relative"
      className="step-number-connector top"
    >
      <Box
        className="ball"
        w={12}
        h={12}
        bg="white"
        color="brand.black"
        borderRadius="100%"
        position="absolute"
        zIndex="2"
        top="calc(50% - 24px)"
        left="calc(50% - 24px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 0 4px 1px white"
      >
        <Text fontSize="3xl" fontWeight="bold">
          {step}
        </Text>
      </Box>
      <Line top={top} bot={bot} />
    </Box>
  )
}

const GridItem = ({ step, title, subtext, image, top, bot }) => {
  return (
    <>
      <StepInfo title={title} subtext={subtext} />
      <StepIndicator step={step} top={top} bot={bot} />
      <StepImage image={image} />
    </>
  )
}

const GridItemEmpty = () => {
  return (
    <>
      <div className="empty" />
      <Box
        alignSelf="center"
        justifySelf="center"
        height="100%"
        position="relative"
        className="step-number-connector"
      >
        <Line />
      </Box>
      <div className="empty" />
    </>
  )
}

const GridItemMobile = ({ step, title, subtext, image, top, bot }) => {
  return (
    <>
      <StepIndicator step={step} top={top} bot={bot} />
      <Box
        flex="1 0 320px"
        display="flex"
        flexDir={{
          base: 'column-reverse',
          sm: 'row',
        }}
        flexWrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
        pl={8}
      >
        <StepInfo title={title} subtext={subtext} />
        <StepImage image={image} />
      </Box>
    </>
  )
}

const GridItemEmptyMobile = () => {
  return (
    <>
      <Box justifySelf="center" alignSelf="center" h="100%" position="relative">
        <Line />
      </Box>
      <div className="empty__mobile-wrapper" />
    </>
  )
}

const HowItWorksPage = () => (
  <Layout>
    <Seo title="How It Works" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <CtaSimple
      title={<>How <strong>CarbonQA</strong> works for your team</>}
      helperText={<>We've developed a process that onboards our team quickly so we can start testing your app and reporting issues in as little as <strong>two weeks</strong>.</>}
      titleSize="3xl"
      pageHeader
    />
    </Box>
    <Box pos="relative" overflow="hidden">
      <Img
        pos="absolute"
        top="5%"
        left="20%"
        w="60px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="15%"
        right="-10%"
        w="240px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="43%"
        left="35%"
        w="100px"
        height="auto"
        opacity="0.25"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="40%"
        left="40%"
        w="120px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="25%"
        left="18%"
        w="40px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="1%"
        left="10%"
        w="160px"
        height="auto"
        opacity="0.75"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Box
        as="section"
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: 4,
          md: 8,
        }}
        py={{
          base: 10,
          md: 20,
        }}
        position="relative"
      >
        {/* Desktop Grid */}
        <Grid
          display={{
            base: 'none',
            md: 'grid',
          }}
          templateColumns="1fr 48px 1fr"
          templateRows="auto 48px auto 48px auto 48px auto 48px auto"
        >
          <GridItem
            top
            step={1}
            title={strings.stepOne.title}
            subtext={strings.stepOne.subtext}
            image={<Illustrations.PenPaper />}
          />
          <GridItemEmpty />
          <GridItem
            step={2}
            title={strings.stepTwo.title}
            subtext={strings.stepTwo.subtext}
            image={<Illustrations.Stack />}
          />
          <GridItemEmpty />
          <GridItem
            step={3}
            title={strings.stepThree.title}
            subtext={strings.stepThree.subtext}
            image={<Illustrations.LightBulb />}
          />
          <GridItemEmpty />
          <GridItem
            step={4}
            title={strings.stepFour.title}
            subtext={strings.stepFour.subtext}
            image={<Illustrations.Slack />}
          />
          <GridItemEmpty />
          <GridItem
            bot
            step={5}
            title={strings.stepFive.title}
            subtext={strings.stepFive.subtext}
            image={<Illustrations.Microchip />}
          />
        </Grid>

        {/* Mobile Grid */}
        <Grid
          display={{
            base: 'grid',
            md: 'none',
          }}
          templateColumns="48px 1fr"
          templateRows="auto 64px auto 64px auto 64px auto 64px auto"
        >
          <GridItemMobile
            top
            step={1}
            title={strings.stepOne.title}
            subtext={strings.stepOne.subtext}
            image={<Illustrations.PenPaper />}
          />
          <GridItemEmptyMobile />
          <GridItemMobile
            step={2}
            title={strings.stepTwo.title}
            subtext={strings.stepTwo.subtext}
            image={<Illustrations.Stack />}
          />
          <GridItemEmptyMobile />
          <GridItemMobile
            step={3}
            title={strings.stepThree.title}
            subtext={strings.stepThree.subtext}
            image={<Illustrations.LightBulb />}
          />
          <GridItemEmptyMobile />
          <GridItemMobile
            step={4}
            title={strings.stepFour.title}
            subtext={strings.stepFour.subtext}
            image={<Illustrations.Slack />}
          />
          <GridItemEmptyMobile />
          <GridItemMobile
            bot
            step={5}
            title={strings.stepFive.title}
            subtext={strings.stepFive.subtext}
            image={<Illustrations.Microchip />}
          />
        </Grid>
      </Box>
    </Box>
    <CtaSimple
      graphic={<LogoStripped w="100%" maxW="120px" />}
      helperText={`On an ongoing basis, you tell us when you have work for our team to test and they'll find and report issues,
      bugs, enhancements, and suggest improvements. They’ll also build and maintain a test plan for your project.`}
      cta="Get Started"
      linkTo="/contact"
      showBg
    />
  </Layout>
)

export default HowItWorksPage
